import { useState, useMemo, useLayoutEffect, useCallback, memo, useEffect } from 'react'

import { HStack, Box } from '@chakra-ui/react'

import { Draggable } from '@fullcalendar/interaction'

import Content from './Content'
import Sidebar from './Sidebar'

import PlanOuvrier from '../components/modals/PlanOuvrier'
import Absence from '../components/modals/Absence'

import ProgrammedHours from '../components/programmedHours'

import { useAuth, useDatas } from 'contexts'
import { useFilters } from '../../contexts/filters'

import { getTimesheetScheduleColor } from 'helpers/utils'

import { convertResources, orderConvertedResources, filterConvertedResources } from './utils'

import moment from 'moment'

import ReactGA from 'react-ga4'

function updateAvailability (newEmployee, setter) {
  setter(prev => prev.map(employee => ({
    ...employee,
    extendedProps: {
      ...employee.extendedProps,
      employee: {
        ...employee.extendedProps.employee,
        isAvailable: employee.extendedProps.employee.id === newEmployee.id
          ? newEmployee.isAvailable
          : employee.extendedProps.employee.isAvailable
      }
    }
  })))
}

const Ouvriers = ({ openEmployeeModal }) => {
  const {
    filters: {
      planningType,
      startDate,
      endDate,
      fullSize,
      position,
      dispo,
      chantier,
      search,
      timesheetOpen,
      timesheetDefaultValues
    },
    setters: {
      setLoading
    },
    actions: {
      closeTimesheetModal: closeTimesheetModalAbs
    },
    datas: {
      companies
    }
  } = useFilters()
  const { callApi } = useAuth()
  const { datas: { defaultDays, clientSlug } } = useDatas()

  const generateAddPlanning = (employee) => {
    return {
      start: startDate,
      end: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      resourceId: employee.id,
      id: employee.id,
      title: 'Ajouter au planning',
      backgroundColor: 'transparent',
      extendedProps: {
        employee
      },
      borderColor: 'transparent',
      classNames: ['timesheet']
    }
  }

  const generateEvent = (timesheetSchedule, employee) => {
    const returnValues = []
    if (timesheetSchedule?.timesheetScheduleAbsences?.length) {
      timesheetSchedule?.timesheetScheduleAbsences.forEach(abs => {
        returnValues.push(generateAbsence(timesheetSchedule, employee, abs))
      })
    }

    if (!timesheetSchedule?.constructionSite?.id) {
      return returnValues
    }

    returnValues.push({
      start: timesheetSchedule?.startFull,
      end: timesheetSchedule?.endFull,
      resourceId: employee?.id,
      id: `${timesheetSchedule?.id}`,
      title: timesheetSchedule?.constructionSite?.name,
      backgroundColor: timesheetSchedule?.constructionSite?.color ?? '#ff5464', // getTimesheetScheduleColor(timesheetSchedule.endDate).event,
      extendedProps: {
        accentColor: getTimesheetScheduleColor(timesheetSchedule.endDate).accent,
        employee,
        company: companies.filter(c => (c.employees || []).map(e => e?.id).includes(employee.id))[0],
        timesheetSchedule,
        employeeId: employee?.id,
        employeeIsValidated: employee?.isEmployeeValidated,
        employeeFullName: employee?.fullName,
        employeePosition: employee?.employeePosition?.name,
        employeePositionId: employee?.employeePosition?.id,
        picture: employee?.picture,
        constructionSite: timesheetSchedule?.constructionSite,
        team: timesheetSchedule?.team
      },
      resourceEditable: false,
      borderColor: 'transparent',
      classNames: ['timesheet'],
      editable: true
    })
    return returnValues
  }

  const generateAbsence = (timesheetSchedule, employee, timesheetScheduleAbsence) => {
    return {
      start: timesheetScheduleAbsence?.startFull,
      end: timesheetScheduleAbsence?.endFull,
      resourceId: employee?.id,
      id: `abs-${timesheetScheduleAbsence?.id}`,
      title: `${timesheetScheduleAbsence?.absence?.label} ${timesheetSchedule.constructionSite ? `(${timesheetSchedule.constructionSite?.name})` : ''}`,
      backgroundColor: '#363a58',
      extendedProps: {
        timesheetId: `${timesheetSchedule.id}`,
        absence: timesheetScheduleAbsence,
        accentColor: getTimesheetScheduleColor(timesheetSchedule.endDate).accent,
        employee,
        company: companies.filter(c => c.employees.map(e => e.id).includes(employee.id))[0],
        timesheetSchedule,
        employeeId: employee?.id,
        employeeIsValidated: employee?.isEmployeeValidated,
        employeeFullName: employee?.fullName,
        employeePosition: employee?.employeePosition?.name,
        employeePositionId: employee?.employeePosition?.id,
        picture: employee?.picture,
        constructionSite: timesheetSchedule?.constructionSite,
        team: timesheetSchedule?.team
      },
      resourceEditable: false,
      borderColor: 'transparent',
      classNames: ['timesheet'],
      editable: true
    }
  }

  useEffect(() => {
    if (planningType === 'ouvrier') {
      getOuvriersTimesheet()
    }
  }, [companies, planningType])

  const [contentDatas, setContentDatas] = useState([])
  const [resources, setResources] = useState([])
  const [planOuvrierOpen, setPlanOuvrierOpen] = useState(false)
  const [planOuvrierValues, setPlanOuvrierValues] = useState()

  const getOuvriersTimesheet = useCallback(() => {
    // if (!companies?.length) return
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/timesheet/companies/schedules?search=&start_date=${startDate}&end_date=${endDate}`
    })
      .then(res => {
        if (!res) return
        const employees = res?.data?.data

        const newEmployeesTimesheetSchedules = []

        employees.forEach(employee =>
          employee?.timesheetSchedules?.length
            ? newEmployeesTimesheetSchedules.unshift(...employee.timesheetSchedules.flatMap(timesheetSchedule => {
              return generateEvent(timesheetSchedule, employee)
            }).reverse())
            : newEmployeesTimesheetSchedules.unshift(generateAddPlanning(employee))
        )

        // Only if "Toutes les disponibilités" or "Disponible"
        if (dispo.value !== '#1BC181') {
          companies.flatMap(com => com.employees).map((emp) => {
            const hasAlreadySchedule = employees.filter(temp => temp.id === emp.id).length > 0

            if (!hasAlreadySchedule) {
              newEmployeesTimesheetSchedules.unshift(generateAddPlanning(emp))
            }

            return emp
          })
        }

        setContentDatas(newEmployeesTimesheetSchedules)
      })
      .finally(() => setLoading(false))
  }, [companies, dispo, startDate, endDate, chantier])

  useLayoutEffect(() => {
    if (defaultDays <= 0) return

    const createDraggable = (idSelector) => {
      const draggableEl = document.getElementById(idSelector)
      // eslint-disable-next-line no-new
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const id = eventEl.getAttribute('data-id')
          const label = eventEl.getAttribute('data-label')
          const color = eventEl.getAttribute('data-color')
          const extendedProps = JSON.parse(eventEl.getAttribute('data-extendedprops'))

          return {
            id,
            title: label,
            color,
            duration: { days: defaultDays },
            extendedProps
          }
        }
      })
    }

    const createDraggable2 = (idSelector) => {
      const draggableEl = document.getElementById(idSelector)
      // eslint-disable-next-line no-new
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const id = eventEl.getAttribute('data-id')
          const label = eventEl.getAttribute('data-label')
          const color = eventEl.getAttribute('data-color')

          return {
            id,
            title: label,
            color,
            duration: { days: defaultDays }
          }
        }
      })
    }

    createDraggable('fullCalendarExternalItems')
    createDraggable2('fullCalendarExternalItems2')
  }, [defaultDays])

  useEffect(() => {
    setResources(orderConvertedResources({
      resources: convertResources({
        resources: companies
      }),
      clientSlug
    }))
  }, [companies])

  const filteredResources = useMemo(() => {
    setLoading(true)

    const f = filterConvertedResources({
      resources,
      dispo,
      positions: position,
      search,
      chantier,
      events: contentDatas
    })

    setLoading(false)
    return f
  }, [chantier, contentDatas, dispo, position, resources, search])

  const filteredEvents = useMemo(() => {
    if (resources.length === filteredResources.length) {
      return contentDatas
    }
    const employeesToDisplay = filteredResources.map(event => event.id)
    return contentDatas.filter(event => employeesToDisplay.includes(event.resourceId))
  }, [filteredResources, contentDatas])

  const deleteEvent = (res, deletedId, newEndDate) => {
    getOuvriersTimesheet()
    if (res?.data?.data?.id) { updateAvailability(res.data.data, setResources) }
    // let newContentDatas = newEndDate
    //   ? contentDatas.map(prev => deletedId !== `${prev.id}`
    //     ? prev
    //     : {
    //         ...prev,
    //         end: new Date(newEndDate.split('T')[0])
    //       }
    //   )
    //   : contentDatas.filter(prev => deletedId !== `${prev.id}`)
    // if (res?.data?.data) {
    //   newContentDatas = newContentDatas.filter(prev => deletedId !== `${prev.extendedProps.timesheetId}`)
    //   const userEventLeft = newContentDatas.filter(prev => res?.data?.data?.id === prev.resourceId).length
    //   if (userEventLeft === 0) {
    //     newContentDatas.push(generateAddPlanning(res?.data?.data))
    //   }
    // }
    // setContentDatas(newContentDatas)
  }

  const addEvent = (res) => {
    if (!res?.data?.data[0]) return
    const timesheet = res?.data?.data[0]
    const newContentDatas = contentDatas.filter(prev => !(prev.display === 'background' && prev.resourceId === timesheet.employee.id))
    // newContentDatas = [...newContentDatas, ...generateEvent(timesheet, timesheet.employee)]
    newContentDatas.push(...generateEvent(timesheet, timesheet.employee))
    if (res?.data?.data?.length) { updateAvailability(res.data.data[0].employee, setResources) }
    // console.log('newContentDatas', newContentDatas)
    // console.log('generateEvent(timesheet, timesheet.employee)', generateEvent(timesheet, timesheet.employee))
    setContentDatas(newContentDatas)
  }

  const updateEvent = (res, updatedEvent) => {
    if (!updatedEvent) {
      addEvent(res)
      return
    }
    if (!res?.data?.data) return
    const timesheet = res?.data?.data
    let newContentDatas = contentDatas.filter(prev => updatedEvent !== `${prev.id}`)

    newContentDatas = newContentDatas.filter(prev => updatedEvent !== `${prev?.extendedProps?.timesheetId}`)
    // newContentDatas = [...newContentDatas, ...generateEvent(timesheet, timesheet.employee)]
    if (res?.data?.data?.employee) { updateAvailability(res.data.data.employee, setResources) }
    newContentDatas.push(...generateEvent(timesheet, timesheet.employee))
    setContentDatas(newContentDatas)
  }

  const openTimesheetModal = useCallback((data) => {
    setPlanOuvrierValues(data)
    setPlanOuvrierOpen(true)
  }, [setPlanOuvrierValues, setPlanOuvrierOpen])
  const closeTimesheetModal = useCallback(() => {
    setPlanOuvrierValues(null)
    setPlanOuvrierOpen(false)
  }, [setPlanOuvrierValues, setPlanOuvrierOpen])

  const [absenceValues, setAbsenceValues] = useState()

  return <HStack height='100%' align='flex-start'>
    <Absence open={Boolean(absenceValues)} onClose={() => setAbsenceValues(null)} defaultValues={absenceValues} callback={updateEvent} />
    <PlanOuvrier open={planOuvrierOpen} onClose={closeTimesheetModal} defaultValues={planOuvrierValues} callback={updateEvent} />
    <PlanOuvrier open={timesheetOpen} onClose={closeTimesheetModalAbs} defaultValues={timesheetDefaultValues} callback={updateEvent} />
    <Box width='80%' height={fullSize ? '100%' : '830px'}>
      <Content
          addEvent={addEvent}
          deleteEvent={deleteEvent}
          events={filteredEvents}
          filteredResources={filteredResources}
          updateEvent={updateEvent}
          openAbsenceModal={setAbsenceValues}
          openTimesheetModal={openTimesheetModal}
          setEmployeeModal={openEmployeeModal}
      />
      <ProgrammedHours datas={filteredEvents} />
    </Box>
    <Box width='20%' height={fullSize ? 'calc(100% + 30px)' : '830px'} style={{ marginInlineStart: 0 }} pl='6px' overflow='hidden' justify='space-around' align='center'>
      <Box height='100%' boxShadow='-6px 80px 7px 0px #e2e8f0'>
        <Sidebar events={filteredEvents} addButton={() => {
          ReactGA.event({ action: 'click', category: 'planningClickAddChantierOnGlobalButton', label: 'planningClickAddChantierOnGlobalButton' })
          openTimesheetModal(null)
        }} />
      </Box>
    </Box>

  </HStack>
}

export default memo(Ouvriers)
