import { useState, useEffect, useMemo, memo } from 'react'

import moment from 'moment'

import { Box, Tabs, TabList, Tab, Button, TabPanels, TabPanel, HStack, Text, Divider, Menu, MenuButton, MenuList, MenuItem, Progress } from '@chakra-ui/react'
import { BsThreeDots } from 'react-icons/bs'
import { Input } from 'components'

import { useAuth, useDatas } from 'contexts'
import { useFilters } from '../../contexts/filters'

import AddChantier from 'pages/admin/Chantiers/components/modals/AddChantier'

import { minToDisplayTime } from 'helpers/utils'

const ConstructionSiteTabPanelConstructionSiteItem = ({ constructionSite, openConstructionSite, inFavorite, favoriteAction }) => {
  const { datas: { chantiersConsumedTimes } } = useDatas()

  return <HStack className='fc-event' data-id={constructionSite.id} data-label={constructionSite.name} data-color={constructionSite?.extendedProps?.color ?? '#ff5464'} justify='space-between' width='100%' pt='3'>
    <HStack width='70%' justifyContent='space-between' backgroundColor={constructionSite?.extendedProps?.color ?? '#ff5464'} borderRadius='8px' color='#fff' pl='4' pr='2' py='1.5'>
      <Text fontWeight='500' fontSize='14px'>{constructionSite.name.length > 15 ? `${constructionSite.name.slice(0, 15).trim()}...` : constructionSite.name}</Text>
      <Menu>
        <MenuButton>
          <BsThreeDots />
        </MenuButton>
        <MenuList>
          <MenuItem color='#000' onClick={() => openConstructionSite(constructionSite.extendedProps)}>Modifier la couleur</MenuItem>
          <MenuItem color='#000' onClick={() => favoriteAction(constructionSite.value)}>{inFavorite ? 'Retirer de la liste' : 'Ajouter à la liste'}</MenuItem>
        </MenuList>
      </Menu>
    </HStack>
    <Box align='center'>
      <Text fontSize='10px' fontWeight='bold'>{minToDisplayTime(chantiersConsumedTimes[constructionSite?.value])}</Text>
      <Divider width='50px' borderColor="#A0AEC0" />
      <Text fontSize='10px'>{constructionSite?.extendedProps?.time ? new Intl.NumberFormat('fr-FR').format(constructionSite.extendedProps.time) : '- '}h</Text>
    </Box>
  </HStack>
}

const ConstructionSiteTabPanel = ({ openConstructionSite, constructionSites }) => {
  const { callApi } = useAuth()

  const [searchInput, setSearchInput] = useState('')

  const [favoriteCs, setFavoriteCs] = useState([])
  useEffect(() => {
    const getFavoriteCs = () => {
      callApi({
        method: 'get',
        url: 'admin/favoritecs'
      })
        .then(res => setFavoriteCs((res?.data?.data ?? []).map(c => c.construction_site_id)))
    }

    getFavoriteCs()
  }, [])

  const deleteFavoriteCs = (favoriteCsId) => {
    callApi({
      method: 'delete',
      url: `admin/favoritecs/${favoriteCsId}`
    })
      .then(() => setFavoriteCs(prevs => prevs.filter(prev => prev !== favoriteCsId)))
  }

  const createFavoriteCs = (constructionSiteId) => {
    if (!constructionSiteId) return
    callApi({
      method: 'post',
      url: 'admin/favoritecs',
      data: {
        construction_site_id: constructionSiteId
      }
    })
      .then(() => setFavoriteCs(prevs => [...prevs, constructionSiteId]))
  }

  const cs = useMemo(() => constructionSites.filter(c => favoriteCs.includes(c.value)), [constructionSites, favoriteCs])
  const csInFCS = useMemo(() => constructionSites.filter(c => !favoriteCs.includes(c.value)), [constructionSites, favoriteCs])

  const filteredCS = useMemo(() => {
    const ilow = searchInput.toLowerCase()
    return cs.filter(c => c.name.toLowerCase().includes(ilow))
  }, [searchInput, cs])
  const filteredCSInFCS = useMemo(() => {
    const ilow = searchInput.toLowerCase()

    return csInFCS.filter(c => c.name.toLowerCase().includes(ilow))
  }, [searchInput, csInFCS])

  return <TabPanel>
    <HStack flexDirection='column'>
      <Input name='Rechercher...' value={searchInput} setValue={setSearchInput} />
      <Box id='fullCalendarExternalItems' width='100%' pt='3'>
        {filteredCS.map(cs => <ConstructionSiteTabPanelConstructionSiteItem key={`display-${cs.id}`} constructionSite={cs} openConstructionSite={openConstructionSite} inFavorite={true} favoriteAction={deleteFavoriteCs} />)}
        {filteredCS.length && filteredCSInFCS.length ? <Divider width='95%' borderBottomWidth='4px' borderColor="#e0e0e0" my='4' pt='3' /> : ''}
        {filteredCSInFCS.map(cs => <ConstructionSiteTabPanelConstructionSiteItem key={`display-${cs.id}`} constructionSite={cs} openConstructionSite={openConstructionSite} inFavorite={false} favoriteAction={createFavoriteCs} />)}
      </Box>
    </HStack>
  </TabPanel>
}

const AbsenceTabPanel = ({ events }) => {
  const { datas: { absences } } = useDatas()
  const {
    filters: { endDate },
    utils: { getMinutesCentFromDate }
  } = useFilters()

  const [absencesDone, setAbsencesDone] = useState([])
  useEffect(() => {
    const newAbsencesDone = []

    events.filter(event => Boolean(event?.extendedProps?.absence?.absence)).forEach(event => {
      if (!newAbsencesDone[event.extendedProps.absence.absence.id]) { newAbsencesDone[event.extendedProps.absence.absence.id] = 0 }
      let relativeEndDate = moment(event.end).startOf('days')
      const newEndDate = moment(endDate).add(-1, 'days').startOf('days')
      relativeEndDate = (relativeEndDate.isAfter(newEndDate) ? newEndDate : relativeEndDate).add(1, 'days')
      for (let firstStartDate = moment(event.start); firstStartDate.isBefore(relativeEndDate); firstStartDate.add(1, 'days')) {
        newAbsencesDone[event.extendedProps.absence.absence.id] += getMinutesCentFromDate(firstStartDate)
      }
    })

    setAbsencesDone(newAbsencesDone)
  }, [events])

  return <TabPanel>
    <Box id='fullCalendarExternalItems2' width='100%'>
      {absences.map(absence => <HStack key={absence.value} className='fc-event' data-id={absence.value} data-label={absence.label} data-absence={'abstim'} data-color='#363a58' justify='space-between' mb='3'>
        <Box width='70%' align='center' backgroundColor='#363a58' borderRadius='8px' color='#fff' px='2' py='1.5'>
          <Text fontWeight='500' fontSize='14px'>{absence.label}</Text>
        </Box>
        <Box align='center'>
          <Text fontSize='10px'>{minToDisplayTime(absencesDone[absence?.value], true)}</Text>
        </Box>
      </HStack>)}
    </Box>
  </TabPanel>
}

const OuvriersSidebar = ({ addButton, events }) => {
  const [selectedConstructionSiteToEdit, setSelectedConstructionSiteToEdit] = useState()
  const [loading] = useState(false)
  const { datas: { chantiers: constructionSites } } = useFilters()

  return <>
    {selectedConstructionSiteToEdit ? <AddChantier open={selectedConstructionSiteToEdit} onClose={() => setSelectedConstructionSiteToEdit(null)} chantier={selectedConstructionSiteToEdit} viewOnly editColor/> : ''}
    <Box style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', border: '1px solid #E2E8F0', borderLeft: 0 }} pr='4'>
      <Button data-variant='solid' onClick={addButton}>Ajouter</Button>
    </Box>
    <Box style={{ height: 'calc(100% - 80px)', overflow: 'auto', position: 'relative' }}>
      {loading && <Progress className='progressLoader' size='xs' isIndeterminate />}
      <Tabs align="center">
        <Box width='100%' style={{ padding: '5px 0 15px 0' }}>
          <TabList width='fit-content'>
            <Tab className='sidebartab'>Chantier</Tab>
            <Tab className='sidebartab'>Absence</Tab>
          </TabList>
          <TabPanels>
            <ConstructionSiteTabPanel openConstructionSite={setSelectedConstructionSiteToEdit} constructionSites={constructionSites ?? []} />
            <AbsenceTabPanel events={events} />
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  </>
}

function sidebarPropsAreEqual (prev, next) {
  return prev.events === next.events
}

export default memo(OuvriersSidebar, sidebarPropsAreEqual)
