import { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react'

import { useToast, Text, Box } from '@chakra-ui/react'

import { useAuth, useDatas } from 'contexts'

import { minToDisplayTime } from 'helpers/utils'

import moment from 'moment'

import ReactGA from 'react-ga4'

const planningTypesOptions = [
  {
    val: 'ouvrier',
    name: 'Par ouvrier'
  },
  {
    val: 'chantier',
    name: 'Par chantier'
  },
  {
    val: 'vehicule',
    name: 'Par véhicule'
  }
]

const initialValues = {
  filters: {
    zoom: 0,
    fullSize: false,
    chantier: [],
    startDate: '',
    endDate: '',
    vehiculeDate: '',
    planningType: 'ouvrier',
    fcDuration: { duration: { weeks: 1 }, slotDuration: { days: 1 }, titleFormat: '[Semaine] WW', columnFormat: ['DD', 'ddd'] },
    position: [],
    responsable: { value: 'all', label: 'Tous' },
    selectedCompanies: [],
    search: '',
    timesheetDefaultValues: null,
    timesheetOpen: false,
    loading: false
  },
  setters: {
    setZoom: () => {},
    setFullSize: () => {},
    setChantier: () => {},
    setStartDate: () => {},
    setEndDate: () => {},
    setPlanningType: () => {},
    setFcDuration: () => {},
    setDispo: () => {},
    setPosition: () => {},
    setResponsable: () => {},
    setSelectedCompanies: () => {},
    setSearch: () => {},
    setTimesheetDefaultValues: () => {},
    setTimesheetOpen: () => {},
    getMinutesCentFromDate: () => {},
    setLoading: () => {}
  },
  options: {
    chantiers: [],
    viewConfigs: [],
    planningTypes: [],
    companies: []
  },
  actions: {
    updateDate: () => {},
    updateVehiculeDate: () => {},
    updateFcDuration: () => {},
    handleFullSize: () => {},
    deleteTimesheet: () => {},
    updateOuvrierVehicule: () => {},
    eventResizeChantierSchedule: () => {},
    eventDropChantierSchedule: () => {},
    eventReceiveChantierSchedule: () => {},
    refreshEndDate: () => {}
  },
  datas: {
    companies: [],
    chantiers: [],
    employees: []
  },
  utils: {
    generateDisplayHour: () => {},
    getMinutesCentFromDate: () => {}
  },
  refs: {
    calendrierOuvriersRef: null,
    calendrierChantiersRef: null
  }
}

const FiltersContext = createContext(initialValues)

const FiltersProvider = ({ children }) => {
  const { callApi } = useAuth()
  const {
    datas: {
      defaultHours,
      disponibilities
    },
    reload: {
      consumedTime: reloadConsumedTime
    }
  } = useDatas()
  const toast = useToast()
  const calendrierOuvriersRef = useRef()
  const calendrierChantiersRef = useRef()

  const [chantiersOptions, setChantiersOptions] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [responsablesOptions, setResponsablesOptions] = useState([{ value: 'all', label: 'Tous les responsables' }])

  const [companiesDatas, setCompaniesDatas] = useState([])
  const [chantiersDatas, setChantiersDatas] = useState([])
  const [employeesDatas, setEmployeesDatas] = useState([])

  const [search, setSearch] = useState('')
  const [fullSize, setFullSize] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').format('YYYY-MM-DD'))
  const [vehiculeDate, setVehiculeDate] = useState(moment().format('YYYY-MM-DD'))
  const [zoom, setZoom] = useState(0)
  const [fcDuration, setFcDuration] = useState({ duration: { weeks: 1 }, slotDuration: { days: 1 }, titleFormat: '[Semaine] WW', columnFormat: ['DD', 'ddd'] })
  const [planningType, setPlanningType] = useState('ouvrier')
  const [chantier, setChantier] = useState([])
  const [dispo, setDispo] = useState(disponibilities[0])
  const [position, setPosition] = useState([])
  const [responsable, setResponsable] = useState({ value: 'all', label: 'Tous les responsables' })
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [loading, setLoading] = useState(false)

  const [timesheetDefaultValues, setTimesheetDefaultValues] = useState()
  const [timesheetOpen, setTimesheetOpen] = useState(false)
  const openTimesheetModal = useCallback((data) => {
    setTimesheetDefaultValues(data)
    setTimesheetOpen(true)
  }, [setTimesheetDefaultValues, setTimesheetOpen])
  const closeTimesheetModal = useCallback(() => {
    setTimesheetDefaultValues(null)
    setTimesheetOpen(false)
  }, [setTimesheetDefaultValues, setTimesheetOpen])

  const handleFullSize = useCallback(() => setFullSize(p => !p), [])

  const updateDate = useCallback((direction) => {
    const newStartDate = moment(startDate).add(fcDuration.duration.weeks * direction, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    const newEndDate = moment(newStartDate).add(fcDuration.duration.weeks, 'week').add(-1, 'day').format('YYYY-MM-DD')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    if (calendrierOuvriersRef?.current) calendrierOuvriersRef.current.getApi().changeView('resourceTimeline', newStartDate)
    if (calendrierChantiersRef?.current) calendrierChantiersRef.current.getApi().changeView('resourceTimeline', newStartDate)
  }, [fcDuration, calendrierOuvriersRef?.current, calendrierChantiersRef?.current, startDate])

  const updateVehiculeDate = useCallback((direction) => {
    setVehiculeDate(moment(vehiculeDate).add(direction, 'day').format('YYYY-MM-DD'))
  }, [vehiculeDate])

  const refreshEndDate = useCallback((weeks) => {
    const newEndDate = moment(startDate).add(weeks, 'week').add(-1, 'day').format('YYYY-MM-DD')
    setEndDate(newEndDate)
  }, [startDate, endDate])

  const deleteTimesheet = useCallback(({ id, callback, isVehicule, isAbsence, removeClockings }) => {
    const toDeleteId = isAbsence ? parseInt(id.split('-')[1]) : id
    callApi({
      method: 'delete',
      url: isVehicule ? `admin/timesheet/vehicle/${toDeleteId}` : isAbsence ? `admin/timesheet/employee/absence/${toDeleteId}?removeClockings=${removeClockings ? 1 : 0}` : `admin/timesheet/${toDeleteId}?removeClockings=${removeClockings ? 1 : 0}`
    })
      .then(res => {
        if (!res) return
        const ruleNumber9 = typeof res?.data === 'string' && !isVehicule && !isAbsence
        toast({
          position: 'bottom-right',
          description: res?.data?.message || (ruleNumber9 ? 'Plannification modifiée avec succès' : 'Plannification supprimée avec succès'),
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadConsumedTime()
        callback(res, id, ruleNumber9 ? res.data : null)
      })
  }, [])

  const getMinutesCentFromDate = useCallback((date) => {
    return defaultHours?.length ? defaultHours.filter(h => h.slug === moment(date).format('dddd').toLowerCase())[0].minutesCent : 0
  }, [defaultHours])

  const displayDailyHour = useCallback((cDate, events) => {
    const currentDate = moment(cDate)
    let firstDate = null
    let lastDate = null
    if (fcDuration.duration.weeks === 1) {
      firstDate = moment(cDate).startOf('day')
      lastDate = moment(cDate).endOf('day')
    } else if (fcDuration.duration.weeks === 2 && currentDate.isoWeekday() === 4) {
      firstDate = moment(cDate).startOf('week')
      lastDate = moment(cDate).endOf('week')
    } else if (fcDuration.duration.weeks === 4 && currentDate.date() === 17) {
      firstDate = moment(cDate).startOf('month')
      lastDate = moment(cDate).endOf('month')
    } else if (fcDuration.duration.weeks === 12 && currentDate.date() === 15) {
      firstDate = moment(cDate).startOf('month')
      lastDate = moment(cDate).endOf('month')
    } else if (fcDuration.duration.weeks === 24 && [3, 7, 11, 15, 19, 24, 28, 33, 37, 42, 46, 50].includes(currentDate.isoWeek())) {
      firstDate = moment(cDate).startOf('month')
      lastDate = moment(cDate).endOf('month')
    } else {
      return false
    }

    // const firstDate = moment(cDate)
    // const lastDate = moment(cDate)
    let minutesCent = 0
    for (firstDate; firstDate.isSameOrBefore(lastDate); firstDate.add(1, 'days')) {
      const nbEventCDate = events.filter(event =>
        event.display !== 'background' &&
        !event?.extendedProps?.absence &&
        moment(event.start).startOf('day').isSameOrBefore(firstDate.endOf('day')) &&
        moment(event.end).isSameOrAfter(firstDate.startOf('day'))
      ).length
      minutesCent += getMinutesCentFromDate(firstDate) * nbEventCDate
    }

    return minToDisplayTime(minutesCent)
  }, [defaultHours, getMinutesCentFromDate, minToDisplayTime, fcDuration])

  const generateDisplayHour = useCallback((datas) => {
    const eDate = moment(endDate)

    const rows = []

    for (const sDate = moment(startDate); sDate.isSameOrBefore(eDate); sDate.add(1, fcDuration.duration.weeks === 24 ? 'week' : 'day')) {
      const value = displayDailyHour(sDate, datas)
      if (value) {
        rows.push(<Box key={sDate.format('YYYY-MM-DD')}>
        <Text ml='0!' fontWeight='light' fontSize='12' width='100%' align='center' className='totalhours' transform={[7, 11, 15, 19, 24, 28, 33, 37, 46, 50].includes(moment(sDate).isoWeek()) ? 'translateX(75%)' : ''}>{value}</Text>
      </Box>)
      }
    }

    return rows
  }, [startDate, endDate, fcDuration, displayDailyHour])

  const updateOuvrierVehicule = useCallback(({ ouvrierId, from, to, callback, newIndex, oldIndex }) => {
    callApi({
      method: 'patch',
      url: `admin/timesheet/vehicle/employee/${ouvrierId}`,
      data: {
        employee_default_id: null,
        from: from ?? 'other',
        to: to ?? 'other',
        new_index: newIndex ?? 0,
        old_index: oldIndex ?? 0
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Plannification modifié avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
      })
  }, [])

  useEffect(() => {
    const getThem = (url, page, perPage, startDate = '', endDate = '') => {
      callApi({
        method: 'get',
        // url: 'admin/construction-sites?page=1&perPage=300'// &start_date=${startDate}&end_date=${endDate}
        url: `${url}?page=${page}&perPage=${perPage}&start_date=${startDate}&end_date=${endDate}`
      // url: `admin/construction-sites/check/availability?type=timesheet-employee&start_date=${startDate}&end_date=${endDate}`
      })
        .then(res => {
          if (!res?.data) return

          if (res.data.meta.pagination.current_page < res.data.meta.pagination.total_pages) {
            getThem(url, page + 1, perPage)
          }

          const datas = (res?.data?.data || [])
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
            .filter(data => moment(data.startDate).isSameOrBefore(moment(endDate)) && moment(data.endDate).isSameOrAfter(moment(startDate)))
          setChantiersDatas(prev => [...datas])
          const newResponsablesOptions = []
          datas
            .filter(chantier => chantier?.constructionActors?.length)
            .forEach(({ constructionActors }) => {
              constructionActors.forEach(({ constructionActorReferent }) => {
                const currentResponsablesOptions = newResponsablesOptions.map(r => r.value)
                if (!currentResponsablesOptions.includes(constructionActorReferent.id)) {
                  newResponsablesOptions.push({
                    value: constructionActorReferent.id,
                    label: constructionActorReferent.fullName
                  })
                }
              })
            })
          setResponsablesOptions(prev => [...prev, ...newResponsablesOptions])
          setChantiersOptions(prev => [
            ...(datas).flatMap(data => ({
              value: data.id,
              label: data.name
            }))
          ])
        })
    }

    getThem('admin/construction-sites', 1, 1000, startDate, endDate)
  }, [endDate, startDate])

  const timeoutRef = useRef()
  useEffect(() => {
    clearTimeout(timeoutRef.current)
    const getCompanies = () => {
      setLoading(true)

      const url = `admin/timesheet/companies?&start_date=${startDate}&end_date=${endDate}&disponibility=${encodeURIComponent(dispo.value)}&filter=`

      callApi({
        method: 'get',
        url
      })
        .then(res => {
          if (!res) return
          setCompaniesOptions((res?.data?.data || [])
            .filter(c => c?.employees?.length && c.id)
            .map(c => ({ value: c.id, label: c.name ?? '-' })))
          setCompaniesDatas((res?.data?.data || []).filter(c => c?.employees?.length && c.id))
          setEmployeesDatas((res?.data?.data || []).filter(c => c?.employees?.length && c.id).flatMap(c => c.employees.map(e => ({ ...e, company: c }))))
        })
        .finally(() => setLoading(false))
    }
    timeoutRef.current = setTimeout(getCompanies, 1)
  }, [dispo, endDate, startDate])

  const scheduleUpdate = useCallback((scheduleId, scheduleDatas, failedCallback, callback, isAbsence) => {
    if (!scheduleId || !scheduleDatas) return
    callApi({
      method: 'patch',
      url: isAbsence ? `admin/timesheet/employee/absence/${scheduleId}` : `admin/timesheet/${scheduleId}`,
      data: scheduleDatas,
      catchCallback: () => failedCallback()
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Plannification modifié avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadConsumedTime()
        if (callback) callback(res, `${res?.data?.data?.id}`)
      })
  }, [])

  const eventResizeChantierSchedule = useCallback((event, callback) => {
    const data = event.event.extendedProps.absence
      ? {
          timesheet_absence_id: event.event.extendedProps.absence.absence.id,
          start_date: moment(event.event.startStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(event.event.endStr).utc().format('YYYY-MM-DD HH:mm:ss')
        }
      : {
          start_date: moment(event.event.startStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(event.event.endStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          construction_site_id: event.event.extendedProps.constructionSite.id,
          employee_id: event.event.extendedProps.employee.id
        }
    return scheduleUpdate(event.event.extendedProps.absence ? event.event.id.split('-')[1] : event.event.id, data, () => event.revert(), (res, id) => callback(res, id), Boolean(event.event.extendedProps.absence))
  }, [])

  const eventDropChantierSchedule = useCallback((event, callback) => {
    const data = event.event.extendedProps.absence
      ? {
          timesheet_absence_id: event.event.extendedProps.absence.absence.id,
          start_date: moment(event.event.startStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(event.event.endStr).utc().format('YYYY-MM-DD HH:mm:ss')
        }
      : {
          start_date: moment(event.event.startStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(event.event.endStr).utc().format('YYYY-MM-DD HH:mm:ss'),
          construction_site_id: event.event.extendedProps.constructionSite.id,
          employee_id: event.event.extendedProps.employee.id
        }
    return scheduleUpdate(event.event.extendedProps.absence ? event.event.id.split('-')[1] : event.event.id, data, () => event.revert(), (res, id) => callback(res, id), Boolean(event.event.extendedProps.absence))
  }, [])

  const eventReceiveChantierSchedule = useCallback((event, callback, events) => {
    ReactGA.event({ action: 'click', category: 'planningDropChantier', label: 'planningDropChantier' })
    const dataId = event.draggedEl.getAttribute('data-id')
    const dataTeamId = event.draggedEl.getAttribute('data-team-id') // NEW
    const dataAbsence = event.draggedEl.getAttribute('data-absence')
    const startDate = moment(event.event._instance.range.start).set({ h: 7, m: 0, s: 0 }).format('YYYY-MM-DD HH:mm:ss')
    const endDate = moment(event.event._instance.range.end).set({ h: 17, m: 0, s: 0 }).add(-1, 'days').format('YYYY-MM-DD HH:mm:ss')

    if (dataAbsence === 'abstim') {
      let firstEvent = events.filter(e => e.resourceId === parseInt(event.event._def.resourceIds[0]) && !e.extendedProps.absence)[0]
      if (firstEvent?.display === 'background') firstEvent = null

      openTimesheetModal({
        startDate: firstEvent ? firstEvent?.start : startDate,
        endDate: firstEvent ? firstEvent?.end : endDate,
        employeeId: event.event._def.resourceIds[0] ?? null,
        absenceStartDate: startDate ?? '',
        absenceEndDate: endDate ?? '',
        absenceId: dataId,
        chantierCanChange: true
      })
    } else {
      callApi({
        method: 'post',
        url: 'admin/timesheet/employee?from=weekly',
        data: Object.assign(
          {
            employee_id: planningType === 'ouvrier' ? event.event._def.resourceIds : dataId.split(','), // [dataId]
            construction_site_id: planningType === 'ouvrier' ? dataId : event.event._def.resourceIds[0],
            start_date: startDate,
            end_date: endDate
          },
          dataTeamId ? { team_id: dataTeamId } : {}
        )
      })
        .then(res => {
          if (!res) return
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Employés ajoutés au planning avec succès',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          reloadConsumedTime()
          callback(res)
        })
    }

    event.revert()
  }, [planningType])

  return (
    <FiltersContext.Provider value={{
      filters: {
        zoom,
        fullSize,
        chantier,
        startDate,
        endDate,
        vehiculeDate,
        planningType,
        fcDuration,
        dispo,
        position,
        responsable,
        selectedCompanies,
        search,
        timesheetDefaultValues,
        timesheetOpen,
        loading
      },
      setters: {
        setZoom,
        setFullSize,
        setChantier,
        setStartDate,
        setEndDate,
        setPlanningType,
        setFcDuration,
        setDispo,
        setPosition,
        setResponsable,
        setSelectedCompanies,
        setSearch,
        setTimesheetDefaultValues,
        setTimesheetOpen,
        setLoading
      },
      options: {
        chantiers: chantiersOptions,
        planningTypes: planningTypesOptions,
        responsables: responsablesOptions,
        companies: companiesOptions
      },
      actions: {
        handleFullSize,
        updateDate,
        updateVehiculeDate,
        deleteTimesheet,
        updateOuvrierVehicule,
        eventResizeChantierSchedule,
        eventDropChantierSchedule,
        eventReceiveChantierSchedule,
        refreshEndDate,
        openTimesheetModal,
        closeTimesheetModal
      },
      utils: {
        generateDisplayHour,
        getMinutesCentFromDate
      },
      datas: {
        companies: companiesDatas,
        chantiers: chantiersDatas,
        employees: employeesDatas
      },
      refs: {
        calendrierOuvriersRef,
        calendrierChantiersRef
      }
    }}>
      {children}
    </FiltersContext.Provider>
  )
}

const useFilters = () => {
  return useContext(FiltersContext)
}

export { FiltersProvider, useFilters }
