import { memo, useMemo } from 'react'

import moment from 'moment'

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import frLocale from '@fullcalendar/core/locales/fr'
import interaction from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import { Button, Box, Text, Avatar, HStack, Divider, Tooltip } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { MdDragIndicator } from 'react-icons/md'
import { RiTeamLine } from 'react-icons/ri'

import { useFilters } from '../../contexts/filters'
import { useModals, useDatas } from 'contexts'

import { minToDisplayTime } from 'helpers/utils'

const SlotLabelContent = ({ arg }) => {
  const {
    filters: {
      fcDuration,
      endDate
    }
  } = useFilters()

  const {
    datas: {
      hideWeekEnd
    }
  } = useDatas()

  return <HStack height='70px' justify='center' align='center' flexDirection='column' className={
      ([24].includes(fcDuration.duration.weeks) && (moment(arg.date).month() !== moment(arg.date).add(1, 'week').month())) ||
      ([12].includes(fcDuration.duration.weeks) && (moment(arg.date).month() !== moment(arg.date).add(1, 'day').month())) ||
      (![24, 12].includes(fcDuration.duration.weeks) && (moment(arg.date).isoWeekday() === (hideWeekEnd ? 5 : 7) && !moment(arg.date).isSame(moment(endDate).add(-1, 'day'))))
        ? 'mbborder'
        : ''
    }>
      {[24].includes(fcDuration.duration.weeks) && [3, 7, 11, 15, 19, 24, 28, 33, 37, 42, 46, 50].includes(moment(arg.date).isoWeek())
        ? <Text ml='0!' fontWeight='light' fontSize='12' width='100%' height='24px' align='center' transform={[7, 11, 15, 19, 24, 28, 33, 37, 46, 50].includes(moment(arg.date).isoWeek()) ? 'translateX(75%)' : ''}>{moment(arg.date).format('MMMM')}</Text>
        : [12].includes(fcDuration.duration.weeks) && moment(arg.date).date() === 15
            ? <Text ml='0!' fontWeight='light' fontSize='12' height='24px'>{moment(arg.date).format('MMM')}</Text>
            : [4, 2].includes(fcDuration.duration.weeks) && moment(arg.date).isoWeekday() === 4
                ? <Text ml='0!' fontWeight='light' fontSize='12' height='24px'>S.{moment(arg.date).isoWeek()}</Text>
                : [1].includes(fcDuration.duration.weeks)
                    ? ''
                    : <Text height='24px'/>
      }
      {fcDuration?.columnFormat?.map(f => <Text ml='0!' key={f}>{ moment(arg.date).format(f) }</Text>)}
    </HStack>
}

const ResourceAreaHeaderContent = ({ arg }) => {
  const {
    actions: {
      updateDate
    }
  } = useFilters()

  return <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingTop: '10px' }} px='2'>
    <Button data-variant='outline' onClick={() => updateDate(-1)} style={{ padding: 0, borderRadius: '4px' }}>
      <ChevronLeftIcon size="22" />
    </Button>
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color='#8186aa'>{ arg.view.title }</Box>
    <Button data-variant='outline' onClick={() => updateDate(1)} style={{ padding: 0, borderRadius: '4px' }}>
      <ChevronRightIcon size="22" />
    </Button>
  </Box>
}

const ResourceLabelContent = ({ arg, openConstructionSiteModal }) => {
  const { datas: { chantiersConsumedTimes } } = useDatas()

  return <Box width='100%' height='100%' borderBottom='1px solid #d2d2d6' style={{ cursor: 'pointer' }} pt='3' pb='6' onClick={() => openConstructionSiteModal(arg.resource.extendedProps)}>
    <Box borderRight={`4px solid ${arg?.resource?.extendedProps?.color ?? '#ff5464'}`} py='4' /* mr='-2' */ style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }} height='100%'>
      <Text fontWeight='bold' fontSize='16px' px='4' textAlign='center' whiteSpace='initial'>{arg.resource.extendedProps.name}</Text>
      <Text fontSize='14px' mt='1' style={{
        width: '80%',
        whiteSpace: 'normal',
        textAlign: 'center'
      }}>{arg.resource.extendedProps.address}</Text>
      <Divider width='100px' my='6' borderColor="#A0AEC0" />
      <Text fontWeight='bold'>{minToDisplayTime(chantiersConsumedTimes[arg.resource.extendedProps.searchResult.id])}</Text>
      <Divider width='50px' borderColor="#A0AEC0" />
      <Text>{arg.resource.extendedProps?.time ? new Intl.NumberFormat('fr-FR').format(arg.resource.extendedProps.time) : '- '}h</Text>
    </Box>
  </Box>
}

const EventContent = ({ arg, openTimesheetModal, deleteEvent }) => {
  const { openConfirmToDeleteModal } = useModals()
  const {
    filters: {
      fcDuration
    },
    actions: {
      deleteTimesheet
    }
  } = useFilters()

  return <Tooltip
    placement="auto"
    label={arg.event.extendedProps.employee?.fullName ?? ''}
    color='white'
  >
      <HStack style={{ borderRadius: '7px' }} position='relative' justify={moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) || moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) ? 'space-between' : 'center'}>
        {moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) && <MdDragIndicator size="22" color={arg.event.extendedProps.accentColor} />}
        {(moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) ? moment(arg.event.end) : moment(arg.view.currentEnd)).diff((moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) ? moment(arg.event.start) : moment(arg.view.currentStart)), 'hours') > (fcDuration.duration.weeks - 1) * 24
          ? <Box className={'eventContent chantiers'}>
              <Box className='actions' height="23px">
                <Button bg='#EDF2F7' color='#000' px='4' mr='1' onClick={() => {
                  openTimesheetModal({
                    id: arg.event.id,
                    name: arg.event.extendedProps?.constructionSite?.name ?? '',
                    startDate: arg.event.start,
                    endDate: arg.event.end,
                    employeeId: arg.event.extendedProps.employee.id,
                    chantierId: arg.event.extendedProps.constructionSite.id
                  })
                }}>Modifier</Button>
                <Button bg='#EDF2F7' color='#000' px='4' ml='1' onClick={() => openConfirmToDeleteModal({
                  label: 'Plannification ouvriers',
                  items: [{ id: arg.event.id, label: arg.event.extendedProps.employee.fullName }],
                  confirm: () => deleteTimesheet({ id: arg.event.id, callback: deleteEvent }),
                  customMessage: "⚠️ Attention, en supprimant la planification de cet employé, seul la période pour laquelle il n'a pas pointé sera supprimée."
                })}>Supprimer</Button>
              </Box>
              <Box className='title' position='relative'>
                <Avatar mr='3' boxSize='10' size='sm' name={arg.event.extendedProps.employee?.fullName} src={arg.event.extendedProps.employee?.picture?.uri ?? null} />
                <Box align='left' className='naming'>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <Text mt='0.5' fontWeight='bold' fontSize='13px'>{arg.event.extendedProps.employee?.fullName}</Text>
                    {arg.event.extendedProps.team ? <><RiTeamLine /><Text fontSize='10px'>({arg.event.extendedProps.team?.name})</Text></> : ''}
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Text mt='-1' fontSize='11px'>{arg.event?.extendedProps?.employeePosition?.name ?? '-'}</Text>
                    <span style={{ width: '8px', height: '8px', background: arg.event?.extendedProps?.employee?.isAvailable, borderRadius: '50%', marginLeft: '5px' }} />
                  </Box>
                  <Text mt='-1' fontSize='11px'>{arg.event.extendedProps.company?.name ?? '-'}</Text>
                </Box>
              </Box>
            </Box>
          : ''
      }
      {moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) && <MdDragIndicator size="22" color={arg.event.extendedProps.accentColor} />}
    </HStack>
  </Tooltip>
}

const ChantiersContent = ({ events, filteredEvents, openTimesheetModal, openConstructionSiteModal, addEvent, updateEvent, deleteEvent }) => {
  const { datas: { hideWeekEnd } } = useDatas()
  const {
    filters: {
      fcDuration,
      chantier: chantiersFilter,
      responsable
    },
    actions: {
      eventResizeChantierSchedule,
      eventDropChantierSchedule,
      eventReceiveChantierSchedule
    },
    datas: {
      chantiers
    },
    refs: {
      calendrierChantiersRef
    }
  } = useFilters()

  const filteredResources = useMemo(() => {
    const chantiersWithEvents = filteredEvents.map(event => event.resourceId)

    console.log(chantiersFilter)
    return chantiers
      .filter(chantier => {
        return (chantiersFilter.length === 0 || chantiersFilter.filter((temp) => temp.value === chantier.id).length > 0) &&
          (events.length === filteredEvents.length || chantiersWithEvents.includes(chantier.id)) &&
         (responsable.value === 'all' || chantier.constructionActors.flatMap(({ constructionActorReferent }) => constructionActorReferent).map(c => c.id).includes(responsable.value))
      })
  }, [chantiers, chantiersFilter, responsable, filteredEvents])

  return <FullCalendar
    ref={calendrierChantiersRef}
    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    plugins={[resourceTimelinePlugin, momentPlugin, interaction]}
    locale={frLocale}
    height='800px'
    headerToolbar={false}
    hiddenDays={hideWeekEnd ? [6, 0] : []}
    editable={true}
    selectMirror={true}
    initialView='resourceTimeline'
    resourceGroupField=''
    resourceAreaWidth={301}
    events={filteredEvents}
    resources={filteredResources}
    droppable={true}
    resourceOrder={'-value'}
    eventResize={(e) => eventResizeChantierSchedule(e, updateEvent)}
    eventDrop={(e) => eventDropChantierSchedule(e, updateEvent)}
    eventReceive={(e) => eventReceiveChantierSchedule(e, addEvent)}
    eventBorderColor='transparent'
    views={{
      resourceTimeline: {
        type: 'timeline',
        ...fcDuration
      }
    }}
    /* CURRENT WEEK (RIGHT) - FORMAT DD ddd. */
    slotLabelContent={(arg) => <SlotLabelContent arg={arg} />}
    /* CURRENT WEEK (LEFT) - FORMAT [Semaine] num - YYYY */
    resourceAreaHeaderContent={(arg) => <ResourceAreaHeaderContent arg={arg} />}
    /* LISTE CHANTIERS */
    resourceLabelContent={(arg) => <ResourceLabelContent arg={arg} openConstructionSiteModal={openConstructionSiteModal} />}
    /* PLANNIFICATION EVENT */
    eventContent={(arg) => <EventContent arg={arg} openTimesheetModal={openTimesheetModal} deleteEvent={deleteEvent} />}
  />
}

export default memo(ChantiersContent)
