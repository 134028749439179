import { useState, useEffect, memo } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Grid, GridItem } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import moment from 'moment'

import { useAuth, useDatas } from 'contexts'

const Add = ({ open, onClose, defaultValues, callback }) => {
  const { callApi } = useAuth()
  const { datas: { chantiers: chantierOptions, employees: employeeOptions, absences, defaultDays } } = useDatas()
  const toast = useToast()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [chantier, setChantier] = useState()
  const [employees, setEmployees] = useState([])

  const [absence, setAbsence] = useState()

  const [absenceStartDate, setAbsenceStartDate] = useState(null)
  const [absenceEndDate, setAbsenceEndDate] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setStartDate(defaultValues?.startDate ? moment(defaultValues.startDate).format('yyyy-MM-DDTHH:mm') : moment().set({ h: 7, m: 0, s: 0 }).format('yyyy-MM-DDTHH:mm'))
    setEndDate(defaultValues?.endDate ? moment(defaultValues.endDate).format('yyyy-MM-DDTHH:mm') : moment().add(defaultDays, 'day').set({ h: 17, m: 0, s: 0 }).format('yyyy-MM-DDTHH:mm'))
    setEmployees(defaultValues?.employeeId ? employeeOptions.filter(employee => parseInt(defaultValues?.employeeId) === employee.value) : [])
    setChantier(defaultValues?.chantierId ? chantierOptions.filter(chantier => defaultValues?.chantierId === chantier.value)[0] : '')
    setAbsence(defaultValues?.absenceId ? absences.filter(absence => parseInt(defaultValues?.absenceId) === absence.value)[0] : '')
    setAbsenceStartDate(defaultValues?.absenceStartDate ? moment(defaultValues.absenceStartDate).format('yyyy-MM-DDTHH:mm') : null)
    setAbsenceEndDate(defaultValues?.absenceEndDate ? moment(defaultValues.absenceEndDate).format('yyyy-MM-DDTHH:mm') : null)
  }, [defaultValues])

  const submit = () => {
    if (!chantier?.value && !absence) {
      toast({
        position: 'bottom-right',
        description: 'Le champ chantier est obligatoire.',
        status: 'error',
        duration: 5000,
        isClosable: false
      })
      return
    }
    setSubmitting(true)
    callApi({
      method: defaultValues?.id ? 'patch' : 'post',
      url: defaultValues?.id ? `admin/timesheet/${defaultValues?.id}` : 'admin/timesheet/employee?from=weekly',
      data: Object.assign(
        {
          employee_id: defaultValues?.id && defaultValues?.employeeId ? defaultValues?.employeeId : employees?.length ? employees.map(c => c.value) : null,
          construction_site_id: chantier?.value ?? null,
          start_date: startDate ? moment(startDate).set('hour', 0).set('minute', 0).format('YYYY-MM-DD HH:mm:ss') : '',
          end_date: endDate ? moment(endDate).set('hour', 18).set('minute', 0).format('YYYY-MM-DD HH:mm:ss') : '',
          timesheet_absence_start_date: absenceStartDate ? moment(absenceStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
          timesheet_absence_end_date: absenceEndDate ? moment(absenceEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        absence ? { timesheet_absence_id: absence.value } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || defaultValues?.id ? "Plannification de l'employé modifié avec succès" : 'Employés ajoutés au planning avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res, defaultValues?.id)
        onClose()
      })
      .finally(() => setSubmitting(false))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>{defaultValues?.id ? `Modifier l'assignation au chantier ${defaultValues.name}` : 'Ajouter un nouvel ouvrier au planning'}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>{defaultValues?.id ? `Pour modifier l'assignation de l'employé au chantier ${defaultValues.name}, entrez les informations correspondantes ci-dessous :` : 'Pour associer un nouveau chantier à un ouvrier, entrez les informations correspondantes ci-dessous :'}</Text>
        <Stack spacing={5} my='5'>
          <Select
            name='Chantier concerné'
            value={chantier}
            setValue={setChantier}
            options={chantierOptions}
            isClearable={defaultValues?.chantierCanChange}
            isDisabled={defaultValues?.chantierId && !defaultValues?.chantierCanChange}
          />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} time={false} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} time={false} />
            </GridItem>
          </Grid>
          <Select name='Ouvriers' value={employees} setValue={setEmployees} options={employeeOptions} isMulti isDisabled={Boolean(defaultValues?.employeeId)} />
          <Text>Signaler une absence ?</Text>
          <Select name="Motif d'absence" value={absence} setValue={setAbsence} options={absences} isDisabled={Boolean(defaultValues?.absenceId)} />
          {absence && (<Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début absence' value={absenceStartDate} setValue={setAbsenceStartDate} time={false} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin absence' value={absenceEndDate} setValue={setAbsenceEndDate} time={false} />
            </GridItem>
          </Grid>)}
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button
          isDisabled={!chantier && !absence}
          isLoading={submitting}
          data-variant='solid'
          onClick={submit}
        >
          {defaultValues?.id ? 'Mettre à jour' : 'Valider'}
        </Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default memo(Add)
